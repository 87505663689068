import { required, email, integer } from '@validations'
import { VueAutosuggest } from 'vue-autosuggest'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import { mapState } from 'vuex'
import { kompackAxiosIns, komshipAxiosIns } from '@/libs/axios'
import provider from '@/provider/provider'
import {
  SETTING_PROFILE, UPDATE_PROFILE, UPDATE_PASSWORD, CHECK_WHATSAPP, SMS_OTP, SEND_OTP_WA, OTP_VERIFICATION, VERIFY_OTP_WA, CHECK_NO_MITRA,
} from '@/provider/url'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { NoSpace, isAlphabet, isNumber } from '@/libs/helpers'
import secureLocalStorage from '@/libs/secureLocalstorage'
import PincodeInput from 'vue-pincode-input'
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue'

export default {
  components: {
    VueAutosuggest,
    ValidationProvider,
    ValidationObserver,
    PincodeInput,
    LottieAnimation,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      isWANum: null,
      userId: this?.$store?.state?.auth?.userData?.id,
      email,
      required,
      integer,
      loadingPage: true,
      btnSubmitDisabled: false,
      filteredOptionsDesti: [],
      queryDestination: '',
      selectedDestination: null,
      inputPropsDestination: {
        id: 'inputPropsDestination',
        class: 'form-control',
        placeholder: 'Masukan Kode Pos/Kecamatan',
      },
      limitOptDestination: 10,
      optionDestination: [],
      optionsKetersediaan: [
        { text: 'Penuh', value: 0 },
        { text: 'Tersedia', value: 1 },
      ],
      optionsGender: [
        { text: 'Laki-laki', value: 'Laki-laki' },
        { text: 'Perempuan', value: 'Perempuan' },
      ],
      disabledField: {
        email: true,
        username: true,
        warehouse_name: true,
        avability: true,
        pic_name: true,
        pic_phone: true,
        description: true,
        image_warehouse: true,
        image_logo_url: true,
        owner: true,
        gender: true,
        phone_number: true,
        nik: true,
        image_ktp_url: true,
        destination_id: true,
        detail_address: true,
        building_area: true,
        building_type: true,
        ownership: true,
      },
      dataAkun: {
        email: '',
        username: '',
      },
      dataFulfillment: {
        warehouse_name: '',
        avability: null,
        pic_name: '',
        pic_phone: '',
        description: '',
        image_warehouse: null,
        image_logo_url: null,
        service_status: '',
      },
      dataOwner: {
        owner: '',
        gender: null,
        phone_number: '',
        nik: '',
        image_ktp_url: null,
        isVerification: '',
      },
      dataProperti: {
        destination_id: '',
        detail_address: '',
        building_area: 0,
        building_type: 0,
        ownership: null,
        warehouse_verification: '',
      },
      isOnEdit: false,
      statusProfile: '',
      dataStatusObj: {
        aktif: 'Aktif',
        nonaktif: 'Non - Aktif',
        unverified: 'Belum Diverifikasi',
      },
      actionStatusType: {
        partner: 'partner',
        warehouse: 'warehouse',
        batalverif: 0,
        verifikasi: 1,
        isActivated: 'isActivated',
        aktif: 'aktif',
        nonaktif: 'nonaktif',
      },
      imageFieldFormType: {
        fulfillmentLogo: 'fulfillmentLogo',
        fulfillmentWarehouse: 'fulfillmentWarehouse',
        ownerKTP: 'ownerKTP',
      },
      changedImageData: {
        ktp: null,
        warehouse: [],
        logo: '',
        warehouseDeleted: [],
      },
      prevImg: {
        logo: null,
        warehouse: null,
        ktp: null,
      },

      optionBuildingType: [
        {
          value: null,
          text: 'Pilih jenis bangunan',
        },
      ],
      optionsOwnership: [
        {
          value: null,
          text: 'Pilih jenis kepemilikan',
        },
      ],

      // change password
      old_password: '',
      new_password: '',
      validate_new_password: '',
      message: false,
      contentMessage: '',

      messagePswd: false,
      messageNewPswd: false,
      messageConfirmPswd: false,
      validatePswd: '',
      validateNewPswd: '',
      validateConfirmPswd: '',
      NoSpace,
      isAlphabet,
      isNumber,

      // Gajelas
      countOtp: 60,
      resendOtp: false,
      loadingOtp: false,

      boxIsClicked: '',
      loadingSendVerificationEmail: false,
      loadingSendVerificationNo: false,
      loadingSendVerificationWa: false,
      titleVerification: '',
      verificationMode: '',
      verificationDescriptionMode: '',
      otpItem: '',
      autofocusInputOtp: false,
      otpSubmit: 1,
      errorNumber: false,
      newNumberItem: '',
      sameOldNumber: false,

      otpIsWrong: false,
      activityOtp: '',

      activityEdit: '',
      titlePopup: '',
      confirmChangeTitlePopup: '',
      newEmailItem: '',
      errorEmail: '',
      emailIsUsed: false,
      numberIsUsed: false,
      loadingNewResend: false,
      typeNumber: false,
      isWhatsapp: null,

      isWhatsappNew: false,
      loadingNewSms: false,
      loadingNewWa: false,
      loadingNew: false,
      modeOtp: '',

      picWhatsapp: null,

      phoneCodeList: ['+62'],
      phoneCode: '+62',
      messageValidateNo: '',
    }
  },
  watch: {
    queryDestination: {
      handler(val) {
        if (!val) {
          this.selectedDestination = ''
          this.dataProperti.destination_id = null
        }
        this.fetchDataDestination()
      },
    },
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  mounted() {
    this.getDataDetailMitra()
    this.editFulfillment()
    this.fetchDataSelectOption()
  },
  methods: {
    getDataDetailMitra() {
      provider.list(`${SETTING_PROFILE}?user_id=${this.userId}`)
        .then(({ data }) => {
          this.dataAkun = {
            id: data.partner_warehouses.id,
            user_id: data.partner_warehouses.user_id,
            email: data.partner_warehouses.email,
            username: data.partner_warehouses.username,
          }
          this.dataFulfillment = {
            warehouse_name: data.warehouse.name,
            warehouse_id: data.warehouse.id,
            avability: data.warehouse.availability,
            pic_name: data.warehouse.pic_name,
            pic_phone: data.warehouse.pic_phone,
            description: data.warehouse.description,
            image_warehouse: data.image_warehouse[0].image_url,
            image_logo_url: data.warehouse.image_logo_url,
          }
          this.prevImg.ktp = data.partner_warehouses.image_ktp_url
          this.prevImg.logo = data.warehouse.image_logo_url
          this.prevImg.warehouse = data.image_warehouse.map(x => x.image_url)
          this.dataOwner = {
            owner: data.partner_warehouses.owner,
            gender: data.partner_warehouses.gender,
            phone_number: data.partner_warehouses.phone_number,
            nik: data.partner_warehouses.nik,
            isVerification: data.warehouse.is_verifikasi,
            image_ktp_url: data.image_ktp_url,
          }
          this.dataProperti = {
            destination_id: data.warehouse.destination_id,
            detail_address: data.warehouse.detail_address,
            building_area: data.warehouse.building_area,
            building_type: data.warehouse.building_type,
            ownership: data.warehouse.ownership,
          }
          this.checkWhatsapp(this.dataFulfillment.pic_phone, 'pic')
          this.fetchDataDestinationOne(data.warehouse.destination_id)
          this.$nextTick(() => {
            this.loadingPage = false
          })
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Failed',
              text: 'Galat detail data mitra gudang',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
        })
    },
    fetchDataSelectOption() {
      this.$http('/kompack/select-option/ownership')
        .then(({ data }) => {
          const ownerShip = [
            {
              value: null,
              text: 'Pilih jenis kepemilikan',
            },
          ]

          data.data.forEach(x => {
            ownerShip.push({
              value: x.id,
              text: x.name,
            })
          })
          this.optionsOwnership = ownerShip
        })
      this.$http('/kompack/select-option/building')
        .then(({ data }) => {
          const buildingType = [
            {
              value: null,
              text: 'Pilih jenis bangunan',
            },
          ]
          data.data.forEach(x => {
            buildingType.push({
              value: x.id,
              text: x.name,
            })
          })
          this.optionBuildingType = buildingType
        })
    },
    fetchDataDestinationOne(dataId) {
      this.$http('/kompack/destination', { params: { destination_id: dataId } })
        .then(({ data }) => {
          this.dataProperti.destination_id = data.data.zip_code
          this.queryDestination = data.data.label
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Failed',
              text: 'Galat tambah data mitra gudang',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
        })
    },
    savedatalist() {
      this.btnSubmitDisabled = true
      // body data
      const formData = new FormData()
      formData.append('image_logo', this.changedImageData.logo) // string ($binary)
      formData.append('name', this.dataFulfillment.warehouse_name)
      formData.append('id_warehouse', this.dataFulfillment.warehouse_id)
      formData.append('availability', this.dataFulfillment.avability)
      formData.append('pic_name', this.dataFulfillment.pic_name)
      formData.append('pic_phone', this.dataFulfillment.pic_phone)
      formData.append('description', this.dataFulfillment.description)
      if (this.changedImageData.warehouse) {
        this.changedImageData.warehouse.forEach(x => {
          formData.append('image_warehouse_insert[]', x)
        })
      } else {
        //
      }
      if (this.changedImageData.warehouseDeleted) {
        this.changedImageData.warehouseDeleted.forEach(x => {
          formData.append('image_warehouse_delete[]', x)
        })
      }
      // calling API untuk tambah mitra gudang
      kompackAxiosIns
        .post(UPDATE_PROFILE, formData, {
          headers: {
            'content-type': 'multipart/form-data',
          },
        })
        .then(({ data }) => {
          if (data.code === 200) {
            this.$bvModal.show('modal-tambahmitra-success')
            this.getDataDetailMitra()
            this.disabledField = {
              ...this.disabledField,
              warehouse_name: true,
              avability: true,
              pic_name: true,
              pic_phone: true,
              description: true,
              image_warehouse: true,
              image_logo_url: true,
            }
            this.isOnEdit = false
            this.btnSubmitDisabled = false
          } else {
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: 'Failed',
                text: 'Galat ubah data fulfillment',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            })
          }
        })
        .catch(err => {
          if (err?.response?.data) {
            const dataError = Object.values(err.response.data.errors).map(x => x.join())
            dataError.forEach(dt => {
              this.$toast({
                component: ToastificationContentVue,
                props: {
                  title: 'Galat',
                  text: dt,
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                },
              })
            })
          }
          this.btnSubmitDisabled = false
        })
    },
    editFulfillment() {
      if (this.$route.params.isOnEdit === true) {
        this.editdataFulfillment()
      }
    },
    editdataFulfillment() {
      this.isOnEdit = true
      this.disabledField = {
        ...this.disabledField,
        warehouse_name: false,
        avability: false,
        pic_name: false,
        pic_phone: false,
        description: false,
        image_warehouse: false,
        image_logo_url: false,
      }
    },
    editdataAll(boolData) {
      this.isOnEdit = !this.isOnEdit
      const newDataDisabled = {}
      Object.keys(this.disabledField).map(dt => {
        newDataDisabled[dt] = boolData
        return dt
      })
      this.disabledField = newDataDisabled
      this.getDataDetailMitra()
      this.checkWhatsapp(this.dataFulfillment.pic_phone, 'pic')
    },
    previewLogo(evChange, type) {
      const file = evChange.target.value
      const fileExtension = file.substr(file.lastIndexOf('.') + 1)
      if (
        fileExtension !== 'jpg'
        && fileExtension !== 'jpeg'
        && fileExtension !== 'png'
        && fileExtension !== 'gif'
      ) {
        // eslint-disable-next-line no-alert
        alert('Hanya dapat upload file dengan ekstensi JPG, PNG, JPEG and GIF')
      } else {
        const [dataimg] = evChange.target.files
        let url = null
        const multiFile = []
        switch (type) {
          case this.imageFieldFormType.fulfillmentLogo:
            this.dataFulfillment.image_logo_url = dataimg
            this.changedImageData.logo = dataimg
            url = URL.createObjectURL(dataimg)
            this.prevImg.logo = url
            break
          case this.imageFieldFormType.fulfillmentWarehouse:
            if (this.prevImg.warehouse.length < 8) {
              evChange.target.files.forEach(fl => {
                multiFile.push(fl)
              })
              url = []
              multiFile.forEach(x => {
                if (x instanceof File) {
                  url.push(URL.createObjectURL(x))
                } else {
                  url.push(x)
                }
              })
              if (this.prevImg.warehouse.length + multiFile.length > 8) {
                this.changedImageData.warehouse = [...this.changedImageData.warehouse]
                this.prevImg.warehouse = [...this.prevImg.warehouse]
                this.$toast({
                  component: ToastificationContentVue,
                  props: {
                    title: 'Galat',
                    text: 'Tidak bisa unggah lebih dari 8 berkas gambar',
                    icon: 'AlertCircleIcon',
                    variant: 'danger',
                  },
                })
              } else {
                this.changedImageData.warehouse = [...this.changedImageData.warehouse, ...multiFile]
                this.prevImg.warehouse = [...this.prevImg.warehouse, ...url]
              }
            }
            break
          default:
            break
        }
      }
    },
    getDestinationValue(suggestion) {
      const { item } = suggestion
      this.selectedDestination = item
      this.dataProperti.destination_id = item.id
      return item.label
    },
    onInputChangeDestination(text) {
      if (text === '' || text === undefined) {
        return
      }
      const filtered = this.optionDestination.filter(item => item.label.toLowerCase().indexOf(text.toLowerCase()) > -1).slice(0, this.limitOptDestination)
      this.filteredOptionsDesti = [{
        data: filtered,
      }]
    },
    fetchDataDestination() {
      const filtered = this.optionDestination.filter(item => item.label.toLowerCase().indexOf(this.queryDestination.toLowerCase()) > -1).slice(0, this.limitOptDestination)
      if (!filtered.length) {
        this.$http('/kompack/destination', { params: { search: this.queryDestination } })
          .then(({ data }) => {
            this.filteredOptionsDesti = [{ data: data.data }]
            this.optionDestination = data.data
          })
          .catch(() => {
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: 'Failed',
                text: 'Galat tambah data mitra gudang',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            })
          })
      }
    },
    handleDeleteAllImgWarehouse() {
      this.changedImageData.warehouseDeleted = [...this.prevImg.warehouse, ...this.changedImageData.warehouseDeleted]
      this.changedImageData.warehouse = []
      this.prevImg.warehouse = []
      this.dataFulfillment.image_warehouse = []
    },
    handleDeleteImgWarehouse(indexdt, dtImg) {
      const data = `${indexdt}`
      if (data) {
        const oldData = {
          prevImg: [...this.prevImg.warehouse],
          dataFulfillment: [...this.dataFulfillment.image_warehouse],
        }
        const newData = {
          prevImg: oldData.prevImg.filter((_, indx) => indx !== indexdt),
          dataFulfillment: oldData.dataFulfillment.filter((_, indx) => indx !== indexdt),
        }
        this.prevImg.warehouse = newData.prevImg
        this.changedImageData.warehouse = newData.dataFulfillment.filter(x => x instanceof File)
        this.dataFulfillment.image_warehouse = newData.dataFulfillment
        if (this.isValidHttpUrl(dtImg)) {
          this.changedImageData.warehouseDeleted.push(dtImg)
        }
      } else {
        this.prevImg.warehouse = []
        this.dataFulfillment.image_warehouse = []
      }
    },
    isValidHttpUrl(string) {
      let url
      try {
        url = new URL(string)
      } catch (_) {
        return false
      }
      return url.protocol === 'http:' || url.protocol === 'https:'
    },
    validateSize(dt) {
      // in MiB
      const fileSize = dt.size / 1024 / 1024
      if (fileSize > 2) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: 'Galat',
            text: 'Ukuran berkas tidak boleh lebih dari 2 MB',
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        })
        return false
      }
      return true
    },
    onSavePassword() {
      if (this.new_password !== this.validate_new_password) {
        this.messageConfirmPswd = true
        this.validateConfirmPswd = '*konfirmasi password baru tidak sesuai dengan password baru yang telah di input'
      } else {
        this.changepassword()
      }
    },
    changepassword() {
      this.messagePswd = false
      this.messageNewPswd = false
      this.messageConfirmPswd = false

      provider.update(`${UPDATE_PASSWORD}`, {
        user_id: this.userId,
        password_last: this.old_password,
        password: this.new_password,
        password_confirm: this.validate_new_password,
      })
        .then(response => {
          if (response.code === 200) {
            this.$bvModal.show('success-change-password')
            this.closemodal()
          }
        })
        .catch(error => {
          if (error.response.data.message === 'password past not same') {
            this.messagePswd = true
            this.validatePswd = '*password lama salah'
          } else if (error.response.data.message === 'password new and password confirm not same, check the new password') {
            this.messageConfirmPswd = true
            this.validateConfirmPswd = '*konfirmasi password baru tidak sesuai dengan password baru yang telah di input'
          } else {
            this.messageNewPswd = true
            this.validateNewPswd = '*password harus berisi minimal 8 karakter yang terdiri dari huruf kapital, angka, dan spesial karakter'
          }
        })
    },
    validateFieldPswd() {
      let result = true
      if (this.old_password === '' || this.new_password === '' || this.validate_new_password === '') {
        result = true
      } else {
        result = false
      }
      return result
    },
    validationSubmit() {
      let result = true
      if (this.dataFulfillment.warehouse_name === ''
      || this.dataFulfillment.avability === null
      || this.dataFulfillment.pic_name === ''
      || this.dataFulfillment.pic_phone === ''
      || this.isWANum === false
      || this.dataFulfillment.description === ''
      || this.prevImg.warehouse.length === 0
      || this.dataFulfillment.image_logo_url === ''
      || this.prevImg.logo === null) {
        result = true
      } else {
        result = false
      }
      return result
    },
    closemodal() {
      this.$bvModal.hide('change-password')
      this.old_password = ''
      this.new_password = ''
      this.validate_new_password = ''
      this.messagePswd = false
      this.messageNewPswd = false
      this.messageConfirmPswd = false
    },
    checkWhatsapp: _.debounce(function (number, type) {
      this.isWANum = null
      komshipAxiosIns.post(`${CHECK_WHATSAPP}?phone_no=${number}`)
        .then(res => {
          if (type === 'pic') {
            this.isWANum = true
          }
          if (type === 'owner') {
            this.isWhatsapp = res.data.data
          }
          if (type === 'new_owner') {
            this.newOwnerWhatsapp = res.data.data
            this.errorOwnerNewNumber = ''
          }
        })
        .catch(() => {
          if (type === 'pic') {
            if (this.dataFulfillment.pic_phone.length > 9) {
              this.isWANum = false
            }
          }
          if (type === 'owner') {
            this.isWhatsapp = 'invalid'
          }
          if (type === 'new_owner') {
            this.newOwnerWhatsapp = 'invalid'
          }
        })
    }, 1000),
    formatPIC(value) {
      const numericValue = value.replace(/[^\d]/g, '')

      if (numericValue.startsWith('62')) {
        return numericValue.substr(2)
      }

      return numericValue.substr(0, 15)
    },
    checkPICPhone() {
      this.isWANum = null
      if (this.dataFulfillment.pic_phone.length < 9) {
        this.isWANum = null
      } else {
        this.checkWhatsapp(this.dataFulfillment.pic_phone, 'pic')
      }
    },
    validateInputPicPhone(e) {
      this.isWANum = null
      const charCode = e.which ? e.which : e.keyCode
      if (this.dataFulfillment.pic_phone.length === 0) {
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 48) {
          e.preventDefault()
        }
        if (e.keyCode === 48) {
          e.preventDefault()
        }
        if (e.keyCode !== 56) {
          e.preventDefault()
        }
      }
      if (e.keyCode === 46 || e.keyCode === 45 || e.keyCode === 43) {
        e.preventDefault()
      }
      return true
    },
    onSuccessPasswordOK() {
      this.$bvModal.hide('success-change-password')
      this.$router.go('/login')
      secureLocalStorage.clear()
    },
    countDownTimer() {
      if (this.countOtp > 0) {
        setTimeout(() => {
          this.countOtp -= 1
          if (this.countOtp === 0 && this.otpSubmit < 2) this.otpSubmit += 2
          this.countDownTimer()
        }, 1000)
      }
      if (this.countOtp === 0) {
        this.resendOtp = true
      }
    },
    censorPhone(phone) {
      return '*'.repeat(phone.length) + phone.slice(-3)
    },
    popupEdit(data) {
      this.activityEdit = data
      if (this.activityEdit === 'nomer') {
        this.titlePopup = 'Edit Nomer HP'
        this.checkWhatsapp(this.dataOwner.phone_number, 'owner')
      }
      this.$refs['popup-edit-nomer'].show()
    },
    handleClosePopupEditNomer() {
      this.boxIsClicked = ''
      this.countOtp = 0
      this.loadingNew = false
      this.loadingNewWa = false
      this.loadingNewSms = false
      this.loadingSendVerificationEmail = false
      this.loadingSendVerificationNo = false
      this.loadingSendVerificationWa = false
      this.otpItem = ''
      this.$refs['popup-edit-nomer'].hide()
    },
    handleCloseNewVerification() {
      this.countOtp = 0
      this.boxIsClicked = ''
      this.loadingSendVerificationEmail = false
      this.loadingSendVerificationNo = false
      this.loadingSendVerificationWa = false
      this.$refs['popup-new-verification'].hide()
      this.$refs['popup-new-phone'].hide()
      this.typeNumber = false
      this.otpItem = ''
      this.newEmailItem = ''
      this.newNumberItem = ''
      this.emailIsUsed = false
      this.numberIsUsed = false
      this.errorNumber = false
      this.isWhatsappNew = ''
      this.loadingNewWa = false
      this.loadingNewSms = false
    },
    handleCloseNewOtpVerification() {
      this.countOtp = 0
      this.loadingNew = false
      this.boxIsClicked = ''
      this.loadingSendVerificationEmail = false
      this.loadingSendVerificationNo = false
      this.loadingSendVerificationWa = false
      this.otpItem = ''
      this.emailIsUsed = false
      this.$refs['popup-new-otp-verification'].hide()
    },
    closeSuccessPopup() {
      if (this.activityEdit === 'nomer') {
        window.location.reload()
      }
      if (this.activityEdit === 'email') {
        window.location.reload()
      }
    },
    editNomerHp(data) {
      this.countOtp = 0
      this.loadingNew = false
      this.boxIsClicked = ''
      this.loadingSendVerificationEmail = false
      this.loadingSendVerificationNo = false
      this.loadingSendVerificationWa = false
      this.otpItem = ''
      this.loadingNew = true
      if (this.activityEdit === 'email') this.activityOtp = 'UPDATE_EMAIL'
      if (this.activityEdit === 'nomer') this.activityOtp = 'UPDATE_PHONE_NUMBER'
      if (data) this.boxIsClicked = data
      if (this.boxIsClicked === 'email') {
        this.executeEmail()
      }
      if (this.boxIsClicked === 'wa') {
        this.executeWa()
      }
      if (this.boxIsClicked === 'no') {
        this.executeNo()
      }
    },
    executeWa() {
      this.titleVerification = 'Verifikasi Kode OTP'
      this.verificationDescriptionMode = `Kode verifikasi telah dikirim melalui Whatsapp ke ${this.censorPhone(this.dataOwner.phone_number)}`
      this.loadingSendVerificationWa = true
      kompackAxiosIns.get(`${SEND_OTP_WA}?phone_number=${this.dataOwner.phone_number}&activity=${this.activityOtp}`).then(response => {
        this.otpSubmit = response.data.data.check_request_otp
        this.countOtp = response.data.data.expired_at
        if (this.countOtp === 1 || this.countOtp === -1) this.countOtp = 0
        if (this.countOtp > 0) this.countDownTimer()
        this.autofocusInputOtp = true
        this.$refs['popup-edit-nomer'].hide()
        this.$refs['popup-new-verification'].show()
        this.loadingNew = false
        this.loadingNewResend = false
      }).catch(err => {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: 'Failure',
            icon: 'AlertCircleIcon',
            text: err,
            variant: 'danger',
          },
        }, 2000)
        this.loadingNew = false
        this.boxIsClicked = ''
        this.loadingSendVerificationWa = false
        this.loadingNewResend = false
      })
    },
    executeNo() {
      this.titleVerification = 'Verifikasi OTP'
      this.verificationDescriptionMode = `Kode verifikasi telah dikirim melalui SMS ke ${this.censorPhone(this.dataOwner.phone_number)}`
      this.loadingSendVerificationNo = true
      kompackAxiosIns.post(`/${SMS_OTP}?phone_number=${this.dataOwner.phone_number}&session=otp number`)
        .then(response => {
          this.otpSubmit = response.data.data.check_request_otp
          this.countOtp = response.data.data.expired_at
          if (this.countOtp === 1 || this.countOtp === -1) this.countOtp = 0
          if (this.countOtp > 0) this.countDownTimer()
          this.autofocusInputOtp = true
          this.$refs['popup-edit-nomer'].hide()
          this.$refs['popup-new-verification'].show()
          this.loadingNew = false
          this.loadingNewResend = false
        }).catch(err => {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err,
              variant: 'danger',
            },
          }, 2000)
          this.loadingNew = false
          this.boxIsClicked = ''
          this.loadingSendVerificationNo = false
          this.loadingNewResend = false
        })
    },
    changeMethodOtp() {
      this.$refs['popup-new-verification'].hide()
      this.$refs['popup-edit-nomer'].show()
      this.countOtp = 0
      this.loadingNew = false
      this.boxIsClicked = ''
      this.loadingSendVerificationEmail = false
      this.loadingSendVerificationNo = false
      this.loadingSendVerificationWa = false
      this.otpItem = ''
      this.loadingNewWa = false
      this.loadingNewSms = false
    },
    sendOtpAgain() {
      this.loadingNewResend = true
      this.editNomerHp(this.boxIsClicked)
    },
    newCheckOtp: _.debounce(function () {
      this.otpIsWrong = false
      if (this.otpItem.length === 6) {
        this.autofocusInputOtp = false
        if (this.boxIsClicked === 'no') {
          this.$http_komship.post('/v2/partner/sms/otp/verification', {
            otp: Number(this.otpItem),
            session: 'otp number',
          }).then(response => {
            this.$refs['popup-new-verification'].hide()
            this.newEmailItem = ''
            this.newNumberItem = ''
            this.typeNumber = false
            this.$refs['popup-new-phone'].show()
            this.countOtp = 0
          }).catch(() => {
            this.otpIsWrong = true
          })
        }
        if (this.boxIsClicked === 'wa') {
          kompackAxiosIns.get(`${VERIFY_OTP_WA}?otp=${Number(this.otpItem)}`)
            .then(response => {
              this.$refs['popup-new-verification'].hide()
              this.newEmailItem = ''
              this.newNumberItem = ''
              this.typeNumber = false
              this.$refs['popup-new-phone'].show()
              this.countOtp = 0
            }).catch(() => {
              this.otpIsWrong = true
            })
        }
      }
    }, 1000),
    newCheckConfirmOtp: _.debounce(function () {
      this.otpIsWrong = false
      if (this.otpItem.length === 6) {
        this.autofocusInputOtp = false
        let urls
        let httpMethod
        if (this.activityEdit === 'nomer') {
          if (this.modeOtp === 'sms') {
            urls = `/${OTP_VERIFICATION}`
            httpMethod = 'POST'
          } else {
            urls = VERIFY_OTP_WA
            httpMethod = 'GET'
          }
        }
        const axiosConfig = {
          method: httpMethod,
          url: `${urls}?otp=${Number(this.otpItem)}&session=otp number`,
        }
        kompackAxiosIns.request(axiosConfig).then(response => {
          const params = {}
          let url
          if (this.activityEdit === 'nomer') {
            url = '/v1/kompack/setting/update/owner-phone'
            Object.assign(params, { owner_phone: `${this.newNumberItem}` })
          }
          kompackAxiosIns.put(url, params).then(async res => {
            this.$refs['popup-new-otp-verification'].hide()
            this.$refs['popup-success-nomor'].show()
          })
        }).catch(() => {
          this.otpIsWrong = true
        })
      }
    }, 1000),
    modeOtpWrong() {
      let result
      if (this.boxIsClicked === 'wa') result = 'Whatsapp'
      if (this.boxIsClicked === 'email') result = 'Email'
      if (this.boxIsClicked === 'no') result = 'SMS'
      return result
    },
    formatPhone: _.debounce(function () {
      if (this.formInputEditItem.length < 8) {
        this.errorNoHp = true
      } else {
        this.errorNoHp = false
      }
      if (this.formInputEditItemPasteMode === true) {
        this.formInputEditItem = this.formInputEditItemPaste
      }
      this.formInputEditItemPasteMode = false
    }, 1000),
    validateInputPhone(e) {
      if (e.keyCode === 46 || e.keyCode === 45 || e.keyCode === 43) {
        e.preventDefault()
      }
    },
    formatterPhone(e) {
      this.formInputEditItemPasteMode = true
      this.formInputEditItem = ''
      this.formInputEditItemPaste = ''
      this.formInputEditItemPaste = e.clipboardData.getData('text').replace(/\D/g, '')
      if (this.formInputEditItemPaste.charAt(0) === '0') {
        this.formInputEditItemPaste = this.formInputEditItemPaste.substr(1, this.formInputEditItemPaste.length)
      } else {
        this.formInputEditItemPaste = this.formInputEditItemPaste.substr(0, this.formInputEditItemPaste.length)
      }
      if (this.formInputEditItemPaste.charAt(0) === '6') {
        this.formInputEditItemPaste = this.formInputEditItemPaste.substr(2, this.formInputEditItemPaste.length)
      } else {
        this.formInputEditItemPaste = this.formInputEditItemPaste.substr(0, this.formInputEditItemPaste.length)
      }
      if (this.formInputEditItem.includes('+62') && this.formInputEditItem.charAt(0) !== '0') {
        this.formInputEditItemPaste = this.formInputEditItemPaste.substr(3, this.formInputEditItemPaste.length)
      }
      this.formInputEditItemPaste = `${this.formInputEditItemPaste}`
      this.newNumberItem = this.formInputEditItemPaste
      this.checkFormatNumber()
    },
    valueFormatPhone(e) {
      while (!this.newNumberItem.startsWith('8')) {
        if (this.newNumberItem.length === 0) {
          this.messageValidateNo = ''
          break
        }
        this.newNumberItem = this.newNumberItem.substr(1)
      }
      if (this.newNumberItem.length > 14) {
        this.newNumberItem = this.newNumberItem.substr(0, 14)
      }
    },
    checkFormatNumber() {
      this.numberIsUsed = false
      this.isWhatsappNew = ''
      if (this.errorNumber) {
        if (this.newNumberItem.length < 9) {
          this.errorNumber = true
        } else {
          this.errorNumber = false
        }
      }
      if (this.sameOldNumber) {
        if (this.dataOwner.phone_number === `${this.newNumberItem}`) {
          this.sameOldNumber = true
        } else {
          this.sameOldNumber = false
        }
      }
      if (!this.sameOldNumber && !this.errorNumber && this.newNumberItem.length >= 9) {
        this.checkWhatsappNew()
      }
      if (!this.sameOldNumber && !this.errorNumber && this.newNumberItem.length >= 9) {
        this.checkWhatsappNew()
      }
    },
    checkFormatNumberOnBlur() {
      this.typeNumber = false
      if (this.newNumberItem === '') {
        this.messageValidateNo = 'Nomor wajib diisi, dimulai dari angka 8'
      } else if (this.newNumberItem.length < 9) {
        this.messageValidateNo = 'Minimal 9 angka'
      } else {
        this.messageValidateNo = ''
      }
      if (this.dataOwner.phone_number === `${this.newNumberItem}`) {
        this.sameOldNumber = true
      } else {
        this.sameOldNumber = false
      }
    },
    validateInputPhoneNumber(e) {
      this.typeNumber = false
      this.messageValidateNo = ''
      const charCode = e.which ? e.which : e.keyCode
      if (this.newNumberItem.length === 0) {
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 48) {
          e.preventDefault()
        }
        if (e.keyCode === 48) {
          e.preventDefault()
        }
        if (e.keyCode !== 56) {
          e.preventDefault()
          this.typeNumber = true
        }
      }
      if (e.keyCode === 46 || e.keyCode === 45 || e.keyCode === 43 || e.keyCode === 32) {
        e.preventDefault()
      } else {
        this.checkFormatNumber()
      }
    },
    disableNextVerification(mode) {
      let result = false
      if (mode === 'wa') {
        if (this.newNumberItem.length < 9) {
          result = true
        }
        if (`${this.newNumberItem}` === this.dataOwner.phone_number) {
          result = true
        }
        if (this.isWhatsappNew === 'invalid') {
          result = true
        }
        if (this.errorNumber) {
          result = true
        }
        if (this.loadingNewWa) {
          result = true
        }
      }
      if (mode === 'sms') {
        if (this.newNumberItem.length < 9) {
          result = true
        }
        if (`${this.newNumberItem}` === this.dataOwner.phone_number) {
          result = true
        }
        if (this.errorNumber) {
          result = true
        }
        if (this.loadingNewSms) {
          result = true
        }
      }
      return result
    },
    checkWhatsappNew: _.debounce(function () {
      if (this.newNumberItem.length >= 9 && `${this.newNumberItem}` !== this.dataOwner.phone_number) {
        this.messageValidateNo = ''
        kompackAxiosIns.get(`${CHECK_NO_MITRA}?phone_number=${this.newNumberItem}`).then(res => {
          this.numberIsUsed = res.data.data.registered
          if (this.numberIsUsed === false) {
            komshipAxiosIns.post(`${CHECK_WHATSAPP}?phone_no=${this.newNumberItem}`).then(response => {
              this.isWhatsappNew = response.data.data
            }).catch(err => {
              if (err.response.data.data === 'invalid') {
                this.isWhatsappNew = err.response.data.data
              }
              if (err.response.data.code !== 1001) {
                this.$toast({
                  component: ToastificationContentVue,
                  props: {
                    title: 'Failure',
                    icon: 'AlertCircleIcon',
                    text: err,
                    variant: 'danger',
                  },
                }, 2000)
              }
            })
          }
        }).catch(err => {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err,
              variant: 'danger',
            },
          }, 2000)
        })
      } else {
        this.isWhatsappNew = ''
      }
    }, 1000),
    fetchModeOtp() {
      let mode
      if (this.modeOtp === 'sms') {
        mode = 'SMS'
      } else {
        mode = 'Whatsapp'
      }
      return mode
    },
    changeMethodOtpConfirmation() {
      this.$refs['popup-new-otp-verification'].hide()
      this.$refs['popup-new-phone'].show()
      this.countOtp = 0
      this.otpItem = ''
      this.loadingNewWa = false
      this.loadingNewSms = false
      this.checkWhatsapp()
    },
    sendOtpConfirmationAgain() {
      this.nextStepVerificationChangeNo(this.modeOtp)
    },
    nextStepVerificationChangeNo(data) {
      this.modeOtp = data
      if (data === 'wa') {
        this.loadingNewWa = true
        this.loadingNewSms = false
        this.boxIsClicked = 'wa'
      } else {
        this.loadingNewSms = true
        this.loadingNewWa = false
        this.boxIsClicked = 'no'
      }
      let url
      if (this.activityEdit === 'nomer') {
        if (data === 'sms') {
          url = `/${SMS_OTP}?phone_number=${this.newNumberItem}&session=otp number`
          kompackAxiosIns.post(url).then(res => {
            this.otpSubmit = res.data.data.check_request_otp
            this.countOtp = res.data.data.expired_at
            if (this.countOtp === 1 || this.countOtp === -1) this.countOtp = 0
            if (this.countOtp > 0) this.countDownTimer()
            this.autofocusInputOtp = true
            this.typeNumber = false
            this.$refs['popup-new-phone'].hide()
            this.$refs['popup-new-otp-verification'].show()
            this.loadingNew = false
            this.otpItem = ''
          }).catch(error => {
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: 'Failure',
                icon: 'AlertCircleIcon',
                text: error,
                variant: 'danger',
              },
            }, 2000)
            this.loadingNew = false
            this.loadingNewWa = false
            this.loadingNewSms = false
          })
        } else {
          url = `${SEND_OTP_WA}?phone_number=${this.newNumberItem}&activity=UPDATE_PHONE_NUMBER`
          kompackAxiosIns.get(url).then(res => {
            this.otpSubmit = res.data.data.check_request_otp
            this.countOtp = res.data.data.expired_at
            if (this.countOtp === 1 || this.countOtp === -1) this.countOtp = 0
            if (this.countOtp > 0) this.countDownTimer()
            this.autofocusInputOtp = true
            this.typeNumber = false
            this.$refs['popup-new-phone'].hide()
            this.$refs['popup-new-otp-verification'].show()
            this.loadingNew = false
            this.otpItem = ''
          }).catch(error => {
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: 'Failure',
                icon: 'AlertCircleIcon',
                text: error,
                variant: 'danger',
              },
            }, 2000)
            this.loadingNew = false
            this.loadingNewWa = false
            this.loadingNewSms = false
          })
        }
      }
    },
  },
}
